const { createApp } = Vue

createApp({
  data() {
    return {
      dragDone: true,
      positions:{
        clientX: 0,
        clientY: 0,
        movementY: 0,
        movementX: 0
      },
      scale: {
        max: 2.5,
        min: .9,
        value: 1,
        counter: 0.1
      },
      dataPin: [],
      accordion:{
        lvl1: '',
        lvl2: '',
      },
      pinActive: '',
      dataPopup: {
        id: '',
        x: 887,
        y: 224,
        label: '',
        title: '',
        intro: '',
        button: {
          label: 'Learn more',
          url: '#',
          show: true
        }
      },
      popupState: false
    }
  },
  computed:{
    scaleUpDisabled(){
      return this.scale.value >= this.scale.max
    },
    scaleDownDisabled(){
      return this.scale.value <= this.scale.min
    }
  },
  mounted(){
    this.reCenter()
    window.addEventListener('resize', this.reCenter);
    // this.dataDropdown = dataDropdown
    this.dataPin = dataPin
  },
  methods:{
    dragMouseDown: function (event) {
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      this.dragDone = false
    },
    elementDrag: function (event) {
      if(window.innerWidth < 767){

        if(!this.dragDone){
          const draggableElement = this.$refs.drag
          const clientX = event.clientX || event.touches[0].clientX
          const clientY = event.clientY || event.touches[0].clientY

          // const imageBounds = draggableElement.getBoundingClientRect();
          // if (clientX > imageBounds.width || clientY > imageBounds.height) {
          //   return; // Do nothing if clientX or clientY exceed image boundaries
          // }
          this.positions.movementX = this.positions.clientX - clientX
          this.positions.movementY = this.positions.clientY - clientY
          this.positions.clientX = clientX
          this.positions.clientY = clientY
          // set the element's new position:
          draggableElement.style.top = (draggableElement.offsetTop - this.positions.movementY) + 'px'
          draggableElement.style.left = (draggableElement.offsetLeft - this.positions.movementX) + 'px'
        }
      }
    },
    closeDragElement () {
      document.onmousemove = null
      this.dragDone = true
    },
    reCenter(){
      const el = this.$refs.drag
      const child = el.children
      const widthImg = el.clientWidth
      const heightImg = el.clientHeight
      const x = (window.innerWidth - widthImg) / 2
      const y = (heightImg - child[0].clientHeight) / 2

      // console.log(child[0].clientHeight + '---' + heightImg)
      // console.log(widthImg, window.outerWidth)
      el.style.top = y + 'px'
      el.style.left = x + 'px'
    },
    scaleUp(){
      const el = this.$refs.drag

      if(this.scale.max > this.scale.value){
        this.scale.value += this.scale.counter
        el.style.transform = `scale(${this.scale.value})`
      }
    },
    scaleDown(){
      const el = this.$refs.drag

      if(this.scale.min < this.scale.value){
        this.scale.value -= this.scale.counter
        el.style.transform = `scale(${this.scale.value})`
      }
    },
    pinClick(v){
      this.accordion.lvl1 = v.targetParentId1
      this.accordion.lvl2 = v.targetParentId2
      this.$refs[v.id][0].click()
      // console.log(this.$refs[v.id])
    },
    locationClick(v){
      this.popupState = true
      this.dataPopup = { ...v }
      // this.dataPopup.pinId = v.target
      // this.dataPopup.label = v.label
      // this.dataPopup.sales = v.sales
      // this.dataPopup.email = v.email
      // this.dataPopup.tel = v.tel
    },
    closePopup(){
      this.popupState = false
      this.dataPopup = {
        id: '',
        x: 0,
        y: 0,
        label: '',
        intro: '',
        button: {
          label: '',
          url: '',
          show: true
        }
      }
    },
    openAccordion(lvl, id){
      if(this.accordion[lvl] === id)
        this.accordion[lvl] = ''
      else
        this.accordion[lvl] = id
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    end(el) {
      el.style.height = ''
    },
  }
}).mount('#maps-app')
